
.c-section__heading {
   font-weight: 400;
   position: relative;
   margin-bottom: ($unit * 3);
   @include mq($from: phablet) {

   }
   @include mq($from: phablet-large) {

   }
   @include mq($from: tablet-port) {

   }
   @include mq($from: tablet-land) {
      margin-bottom: ($unit * 3);
   }
   @include mq($from: lap) {

   }
   @include mq($from: desk) {
      margin-bottom: $unit--huge;
   }
   &:before {
      position: absolute;
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: $mantis;
      left: 50%;
      transform: translateX(-50%);
      bottom: -$unit;
      @include mq($from: tablet-land) {
         width: 9px;
         height: 9px;
      }
      @include mq($from: desk) {
         width: 10px;
         height: 10px;
      }
   }
}

.c-section__heading--left {
   &:before {
      left: 0;
      bottom: -$unit;
      transform: translateX(0);
   }
}
