// Bakcground color utilities

.u-bg-mantis {
   background-color: $mantis;
}

.u-bg-mantis-60 {
   background-color: $mantis-60;
}

.u-bg-firefly {
   background-color: $firefly;
}

.u-bg-firefly-60 {
   background-color: $firefly-60;
}

.u-bg-persia {
   background-color: $persia;
}

.u-bg-persia-60 {
   background-color: $persia-60;
}

.u-bg-seance {
   background-color: $seance;
}

.u-bg-seance-60 {
   background-color: $seance-60;
}

.u-bg-amaranth {
   background-color: $amaranth;
}

.u-bg-amaranth-60 {
   background-color: $amaranth-60;
}

.u-bg-seaspray {
   background-color: $seaspray;
}

.u-bg-seaspray-60 {
   background-color: $seaspray-60;
}

.u-bg-sunglow {
   background-color: $sunglow;
}

.u-bg-sunglow-60 {
   background-color: $sunglow-60;
}

.u-bg-white {
   background-color: $white;
}

.u-bg-white-50 {
   background-color: $white;
}


// Text colour utilities

.u-text-mantis {
   color: $mantis;
}

.u-text-mantis-60 {
   color: $mantis-60;
}

.u-text-firefly {
   color: $firefly;
}

.u-text-firefly-60 {
   color: $firefly-60;
}

.u-text-persia {
   color: $persia;
}

.u-text-persia-60 {
   color: $persia-60;
}

.u-text-seance {
   color: $seance;
}

.u-text-seance-60 {
   color: $seance-60;
}

.u-text-amaranth {
   color: $amaranth;
}

.u-text-amaranth-60 {
   color: $amaranth-60;
}

.u-text-seaspray {
   color: $seaspray;
}

.u-text-seaspray-60 {
   color: $seaspray-60;
}

.u-text-sunglow {
   color: $sunglow;
}

.u-text-sunglow-60 {
   color: $sunglow-60;
}

.u-text-paragraph {
   color: $paragraph;
}

.u-text-paragraph-60 {
   color: $paragraph-60;
}

// Text monochrome utilities

.u-text-white-5 {
   color: $white-5;
}

.u-text-white-10 {
   color: $white-10;
}

.u-text-white-15 {
   color: $white-15;
}

.u-text-white-20 {
   color: $white-20;
}

.u-text-white-25 {
   color: $white-25;
}

.u-text-white-30 {
   color: $white-30;
}

.u-text-white-35 {
   color: $white-35;
}

.u-text-white-40 {
   color: $white-40;
}

.u-text-white-45 {
   color: $white-45;
}

.u-text-white-50 {
   color: $white-50;
}

.u-text-white-55 {
   color: $white-55;
}

.u-text-white-60 {
   color: $white-65;
}

.u-text-white-65 {
   color: $white-65;
}

.u-text-white-70 {
   color: $white-70;
}

.u-text-white-75 {
   color: $white-75;
}

.u-text-white-80 {
   color: $white-80;
}

.u-text-white-85 {
   color: $white-85;
}

.u-text-white-90 {
   color: $white-90;
}

.u-text-white-95 {
   color: $white-95;
}

.u-text-white {
   color: $white;
}
