.u-h1 {
   @include font-size($inuit-font-size-h1, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h1 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h1 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h1 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h1 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h1 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h1 * 1.75), 1.125, $important: true);
   }
}

.u-h2 {
   @include font-size($inuit-font-size-h2, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h2 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h2 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h2 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h2 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h2 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h2 * 1.75), 1.125, $important: true);
   }
}

.u-h3 {
   @include font-size($inuit-font-size-h3, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h3 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h3 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h3 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h3 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h3 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h3 * 1.75), 1.125, $important: true);
   }
}

.u-h4 {
   @include font-size($inuit-font-size-h4, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h4 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h4 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h4 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h4 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h4 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h4 * 1.75), 1.125, $important: true);
   }
}

.u-h5 {
   @include font-size($inuit-font-size-h5, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h5 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h5 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h5 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h5 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h5 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h5 * 1.75), 1.125, $important: true);
   }
}

.u-h6 {
   @include font-size($inuit-font-size-h6, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h6 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h6 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h6 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h6 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h6 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h6 * 1.75), 1.125, $important: true);
   }
}

.u-h7 {
   @include font-size($inuit-font-size-h7, 1.125, $important: true);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h7 * 1.125), 1.125, $important: true);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h7 * 1.25), 1.125, $important: true);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h7 * 1.375), 1.125, $important: true);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h7 * 1.5), 1.125, $important: true);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h7 * 1.625), 1.125, $important: true);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h7 * 1.75), 1.125, $important: true);
   }
}

.u-heading-muli {
   font-family: $base-font-family;
   line-height: 1.25 !important;
}
