
.c-logo {
   display: block;
   position: absolute;
   z-index: 50;
   width: 56px;
   height: 56px;
   left: 24px;
   top: 50%;
   transform: translateY(-50%);
   @include mq($from: tablet-land) {
      width: 64px;
      height: 64px;
      left: 28px;
   }
   @include mq($from: lap) {
      width: 72px;
      height: 72px;
      left: 32px;
   }
   @include mq($from: desk) {
      width: 84px;
      height: 84px;
      left: 36px;
   }
}

.c-logo-fill {
   fill: $mantis;
   transition: all $global-transition--huge $transition--ease-out-quart;
}

.c-logo-link {
   display: block;
}
