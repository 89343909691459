
.c-page-context {
   font-weight: 400;
   color: $paragraph;
   margin-bottom: $unit--small;
   margin-top: 0;
   @include font-size(14px,1.125);
   @include mq($from: phablet) {
      @include font-size(15px,1.25);
   }
   @include mq($from: tablet-port) {
      @include font-size(17px,1.25);
   }
   @include mq($from: lap) {
      @include font-size(20px,1.25);
   }
   @include mq($from: desk) {
      @include font-size(24px,1.25);
   }
   span {
      color: $paragraph-60;
   }
   a {
      background-color: transparent;
      text-decoration: none;
      color: $paragraph;
      transition: all $global-transition ease-in-out;
      border-bottom: 0 none;
      &:hover {
         color: $mantis;
      }
   }

}
