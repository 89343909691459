

.c-site-foot {
   background-color: $white;
   padding-top: $unit--huge * 1.5;
   padding-bottom: $unit--huge;
}

.c-site-foot-logo {
   width: 72px;
   margin-bottom: $unit--large;
   @include mq($from: tablet-land) {
      width: 96px;
   }
   @include mq($from: desk) {
      width: 120px;
   }
}

.c-site-foot-list {
   display: block;
   margin: 0;
   padding: 0;
   list-style: none;
   margin-bottom: $unit--large;
   a {
      @include font-size(12px);
      color: $paragraph;
      text-decoration: none;
      transition: all $global-transition ease-in-out;
      &:hover {
         color: $mantis;
      }
      @include mq($from: tablet-land) {
         @include font-size(13px);
      }
      @include mq($from: desk) {
         @include font-size(14px);
      }
   }
}

.c-site-foot-legal {
    display: inline-block;
    margin-bottom: 0;
    @include font-size(12px,1.5);
    @include mq($from: tablet-land) {
       @include font-size(13px,1.5);
    }
    @include mq($from: desk) {
       @include font-size(14px,1.5);
    }
}

.c-social-link {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-bottom: $unit--small;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.c-social-link--twitter {
    background-image: url(../img/icn-twitter.svg);
}

.c-social-link--facebook {
    background-image: url(../img/icn-facebook.svg);
}

.c-social-link--linkedin {
    background-image: url(../img/icn-linkedin.svg);
}
