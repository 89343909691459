.c-square-friend {
   position: relative;
   &:before {
      position: absolute;
      z-index: 100;
      content: "";
      display: block;
      width: 72px;
      height: 72px;
      background-size: auto;
      background-position: center center;
      background-repeat: no-repeat;
      @include mq($from: tablet-land) {
         width: 96px;
         height: 96px;
      }
      @include mq($from: desk) {
         width: 120px;
         height: 120px;
      }
   }
}

.c-square-friend--top-left {
   &:before {
      top: 0;
      left: 0;
      transform: translate(-50%,-50%);
   }
}

.c-square-friend--top-right {
   &:before {
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
   }
}

.c-square-friend--bottom-left {
   &:before {
      bottom: 0;
      left: 0;
      transform: translate(-50%,50%);
   }
}

.c-square-friend--bottom-right {
   &:before {
      bottom: 0;
      right: 0;
      transform: translate(50%,50%);
   }
}

.c-square-friend--mantis {
   &:before {
      background-image: url(../img/square-mantis.svg);
   }
}

.c-square-friend--firefly {
   &:before {
      background-image: url(../img/square-firefly.svg);
   }
}

.c-square-friend--white {
   &:before {
      background-image: url(../img/square-white.svg);
   }
}

.c-square-friend--smoke {
   &:before {
      background-image: url(../img/square-smoke.svg);
   }
}
