.c-services-card {
   background-color: $white;
   text-align: left;
   width: 100%;
   @include box-shadow(0px,12px,48px,rgba(0,0,0,0.15));
   padding: ($unit * 1.5);
   @include mq($from: phablet) {
      padding: ($unit * 2);

   }
   @include mq($from: phablet-large) {
      // min-height: 350px;

   }
   @include mq($from: tablet-port) {
      // min-height: 325px;

   }
   @include mq($from: tablet-land) {
      padding: ($unit * 2);
      // min-height: 390px;
   }
   @include mq($from: lap) {
      padding: ($unit * 2.5);
   }
   @include mq($from: desk) {
      padding: ($unit * 3);
   }
}



.c-services-card--2,
.c-services-card--5 {
   background-color: $electricity;
   .c-services-card__heading {
      color: $white;
      &:before {
         background-color: $mantis;
      }
   }
   .c-services-card__description {
      color: $white;
   }
   .c-arrow-link {
      color: $white;
      background-image: url(../img/arrow-green.svg);
   }
}

.c-services-card--3,
.c-services-card--6 {
   background-color: $firefly;
   .c-services-card__heading {
      color: $white;
   }
   .c-services-card__description {
      color: $white;
   }
   .c-arrow-link {
      color: $white;
   }
}

.c-services-card__heading {
   position: relative;
   color: $firefly;
   font-family: $heading-font-family;
   font-weight: 400;
   margin-bottom: $unit--large;
   @include mq($from: desk) {
      margin-bottom: ($unit * 2);
   }
   &:before {
      position: absolute;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: $mantis;
      left: 0;
      bottom: -$unit;
      @include mq($from: tablet-land) {
         width: 7px;
         height: 7px;
      }
      @include mq($from: desk) {
         width: 8px;
         height: 8px;
      }
   }
}

.c-services-card__description {
   @include font-size(14px, 1.75);
   @include mq($from: tablet-port) {
      @include font-size(15px, 1.75);
   }
   @include mq($from: lap) {
      @include font-size(16px, 1.75);
   }
   @include mq($from: desk) {
      margin-bottom: ($unit * 2);
   }
}

.c-services-card__sub {
   position: relative;
   color: $firefly;
   font-family: $heading-font-family;
   font-weight: 400;
   margin-bottom: $unit;
}

.c-services-card__related {
   display: block;
   list-style: none;
   padding: 0;
   margin: 0;
}
