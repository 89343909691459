
.c-partner {
   background-color: $white;
   position: relative;
}

.c-partner__img {
   position: absolute;
   width: 40%;
   height: 100%;
   top: 0;
   left: 0;
   background-size: cover;
   background-position: left center;
   background-repeat: no-repeat;
   display: none;
   @include mq($from: tablet-port) {
      display: block;
   }
}

.c-partner__img--raw-brothers {
   background-position: right -30em center;
}

.c-partner__content {
}
