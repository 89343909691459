
.c-hero {
   z-index: 0;
   position: relative;
   height: 100vh;
   overflow: hidden;
}

.c-hero__img {
   position: absolute;
   width: 100%;
   height: 100%;
   left: 50%;
   top: 50%;
   transform: translate(-50%,-50%) scale(1.1);
   background-size: cover;
   background-position: 30% center;
   background-repeat: no-repeat;
   animation-name: zoom;
   animation-duration: 10s;
   animation-iteration-count: 1;
   animation-direction: normal;
   animation-fill-mode: forwards;
   animation-timing-function: linear;
   @include mq($from: tablet-land) {
      background-position: center center;
   }
   @include mq($and: '(min-aspect-ratio: 16/9)') {
      background-position: center top;
   }
}

.c-hero__img-- {
   background-image: url(../img/hero-home.jpg);
}

.c-hero__img--about {
   background-image: url(../img/hero-about-alt.jpg);
}

.c-hero__img--services {
   background-image: url(../img/hero-services.jpg);
}

.c-hero__img--challenges {
   background-image: url(../img/hero-challenges.jpg);
}

.c-hero__img--clients {
   background-image: url(../img/hero-clients.jpg);
}

.c-hero__img--community {
   background-image: url(../img/hero-community.jpg);
}

@keyframes zoom {
   0% {
      transform: translate(-50%,-50%) scale(1);
   }
   100% {
      transform: translate(-50%,-50%) scale(1.1);
   }
}

.c-hero__content {
   position: absolute;
   z-index: 2;
   width: 80%;
   left: 10%;
   bottom: 10%;
   @include mq($from: tablet-land) {
      width: 40%;
   }
}

.c-hero__content-- {
   .c-hero__heading {
      span {
         font-size: 0.333em;
      }
   }
}


.c-hero__content--about {
   bottom: auto;
   top: 55%;
   transform: translateY(-50%);
   .c-hero__heading {
      span {
         text-align: left;
         font-size: 0.5em;
      }
   }
}

.c-hero__content--services {
   bottom: auto;
   top: 50%;
   transform: translateY(-50%);
   .c-hero__heading {
      span {
         line-height: 0.25;
         text-align: left;
         margin-left: 1.75em;
         font-size: 0.575em;
      }
   }
}

.c-hero__content--challenges {
   bottom: auto;
   top: 50%;
   transform: translateY(-50%);
   .c-hero__heading {
      span {
         font-size: 0.575em;
         line-height: 0.75;
         margin-left: 1.5em;
      }
   }
}

.c-hero__content--clients {
   bottom: auto;
   top: 60%;
   transform: translateY(-50%);
   .c-hero__heading {
      line-height: 0.95;
      @include mq($from: tablet-port) {
         font-size: 9vh;
      }
      @include mq($from: tablet-land) {
         font-size: 8vw;
      }
      @include mq($from: lap) {
         font-size: 7vw;
      }
      @include mq($from: desk) {
         font-size: 6vw;
      }
      span {
         line-height: 0;
         text-align: left;
         margin-left: 3.25em;
         font-size: 0.375em;
      }
   }
}

.c-hero__content--community {
   bottom: auto;
   top: 50%;
   transform: translateY(-50%);
   .c-hero__heading {
      span {
         line-height: 0.25;
         text-align: left;
         margin-left: 40%;
         font-size: 0.575em;
      }
   }
}



.c-hero__heading {
   color: $white;
   font-size: 6.5vh;
   line-height: 1.125;
   font-weight: 900;
   opacity: 0;
   transform: translateY($unit--large);
   animation-name: herofadein;
   animation-iteration-count: 1;
   animation-direction: normal;
   animation-fill-mode: forwards;
   animation-timing-function: $global-easing-in;
   animation-duration: 1s;
   @include mq($from: tablet-port) {
      font-size: 7vh;
   }
   @include mq($from: tablet-land) {
      font-size: 5vw;
   }
   @include mq($from: lap) {
      font-size: 5vw;
   }
   @include mq($from: desk) {
      font-size: 5vw;
   }
   span {
      display: block;
      color: $white-60;
      font-weight: 400;
      transform: translateY($unit--large);
      animation-name: herofadein;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-timing-function: $global-easing-in;
      animation-duration: 1s;
      animation-delay: 0s;
   }
}

@keyframes herofadein {
   0% {
      opacity: 0;
      transform: translateY($unit--large);
   }
   20% {
      opacity: 0;
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}

.c-hero__scroll {
   position: absolute;
   display: block;
   width: 18px;
   height: 11px;
   left: 10%;
   bottom: $unit;
   background-image: url(../img/hero-arrow.svg);
   background-repeat: no-repeat;
   background-size: 100% auto;
   background-position: center center;
   text-indent: 100%;
   white-space: nowrap;
   overflow: hidden;
   opacity: 0;
   animation-name: scrollfadein;
   animation-duration: 1s;
   animation-iteration-count: 1;
   animation-direction: normal;
   animation-fill-mode: forwards;
   animation-timing-function: $global-easing-in;
   animation-delay: 0.3s;
   @include mq($from: lap) {
      width: 24px;
      height: 15px;
      bottom: $unit--large;
   }
}

.c-hero__scroll--center {
    left: 50%;
    margin-left: -11px;
    bottom: -$unit !important;
}

.c-hero__scroll--mantis {
    background-image: url(../img/hero-arrow-mantis.svg);
    animation-name: scrollfadeinsolid;
    animation-delay: 2.15s;
}

.c-hero__scroll--firefly {
    background-image: url(../img/hero-arrow-firefly.svg);
    animation-name: scrollfadeinsolid;
    animation-delay: 2.15s;
}


@keyframes scrollfadein {
   0% {
      opacity: 0;
      transform: translateY($unit--large);
   }
   50% {
      opacity: 0;
   }
   100% {
      opacity: 0.6;
      transform: translateY(0);
   }
}

@keyframes scrollfadeinsolid {
   0% {
      opacity: 0;
      transform: translateY($unit--large);
   }
   50% {
      opacity: 0;
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}
