
.c-site-nav {
   display: none;
   position: absolute;
   right: 24px;
   top: 50%;
   transform: translateY(-50%);
   @include mq($from: tablet-port) {
      display: block;
   }
   @include mq($from: tablet-land) {
      right: 28px;
   }
   @include mq($from: desk) {
      right: 32px;
   }
   ul {
      display: block;
      margin: 0;
      padding: 0;
   }
   li {
      display: inline-block;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: $unit--small;
      padding-right: $unit--small;
      @include mq($from: desk) {
         padding-left: $unit--small + $unit--tiny;
         padding-right: $unit--small + $unit--tiny;
      }
   }
}

.c-site-nav__link {
   position: relative;
   padding: $unit--small  $unit--tiny;
   text-decoration: none;
   color: $paragraph;
   @include font-size(14px,1);
   transition: all $global-transition $global-easing-in;
   &:before {
      position: absolute;
      content: "";
      display: block;
      background-color: $mantis;
      bottom: 0;
      left: 0;
      width: 0px;
      height: 1px;
      transition: all $global-transition $global-easing-in;
   }
   &:hover {
      color: $firefly;
      transition: all $global-transition $global-easing-in;
      &:before {
         width: 100%;
         transition: all $global-transition $global-easing-in;
      }
   }
   &.c-site-nav__link--active {
      font-weight: 600;
      color: $firefly;
      &:before {
         width: 100%;
      }
   }
}
