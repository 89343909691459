.c-arrow-link {
   display: inline-block;
   color: $paragraph;
   text-decoration: none;
   padding-right: $unit * 1.5;
   @include font-size(12px,1.25);
   background-image: url(../img/arrow-green.svg);
   background-position: right center;
   background-size: 24px auto;
   background-repeat: no-repeat;
   transition: all $global-transition $global-easing-in;
   &:hover {
      padding-right: $unit * 1.75;
   }
   &.c-arrow-link--center {
      &:hover {
         margin-left: $unit--tiny;
      }
   }
   @include mq($from: phablet-large) {
      @include font-size(13px,1.25);
   }
   @include mq($from: lap) {
      @include font-size(14px,1.25);
   }
}
