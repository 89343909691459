.c-pipeline {
   position: relative;
   &:before,
   &:after {
      display: block;
      content: "";
      position: absolute;
      background-color: $white;
   }
   &:before {
      width: 100vw;
      height: 4px;
      left: -$unit;
      transform: translate(-100%,-50%);
      top: 50%;
   }
   &:after {
      width: 4px;
      height: 100vh;
      transform: translate(-50%,0);
      top: $unit * 2.5;
      left: 50%;
      z-index: -1;
   }
}

.c-pipeline--left {

}

.c-pipeline--right {
   &:before {
      width: 100vw;
      height: 4px;
      left: 115%;
      transform: translate(0,-50%);
      top: 50%;
   }
}

.c-pipeline-split {
   position: relative;
   &:before {
      display: block;
      content: "";
      position: absolute;
      background-color: $white-50;
      width: 4px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: $unit--huge;
   }
}
