// Site navigation

.c-mobile-nav {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	height: 100vh;
   background-color: $mantis;
	&.c-mobile-nav--visible {
		opacity: 1;
		visibility: visible;
		transition: opacity $global-transition--huge $transition--ease-in-quart, visibility 0s linear;
	}
	@include mq($until: tablet-port) {
		transition: opacity $global-transition--huge $transition--ease-in-quart, visibility 0s linear $global-transition--huge;
	}
	@include mq($from: tablet-port) {
		display: none;

	}
}

// Lock body

body {
	&.c-mobile-nav--visible {
		height: 100vh;
		overflow: hidden;
		.c-logo-fill {
		   fill: $white;
			transition: all $global-transition--huge $transition--ease-in-quart;
		}
	}
}

.c-mobile-nav__list {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
	}
}

.c-mobile-nav__link {
	display: block;
	color: $white;
	text-align: center;
	text-decoration: none;
	@include font-size(16px);
	padding: $unit--tiny;
	transition: color $global-transition ease-in-out;
	&:hover {
		color: $white-60;

	}
}
