.c-feature-list {
   li {
      list-style: none;
      position: relative;
      &:before {
         content: "";
         position: absolute;
         display: block;
         width: 6px;
         height: 6px;
         background-color: $mantis;
         left: -($unit - $unit--tiny);
         top: 0.875em;
         transform: translateY(-50%);
      }

   }
}

.c-featire-list--white {
    li {
        &:before {
            background-color: $white;
        }
    }
}

.c-featire-list--firefly {
    li {
        &:before {
            background-color: $firefly;
        }
    }
}
