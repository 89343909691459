
.c-site-head {
   position: relative;;
   background-color: $white;
   width: 100%;
   height: 104px;
   @include mq($from: tablet-land) {
      height: 120px;
   }
   @include mq($from: lap) {
      height: 136px;
   }
   @include mq($from: desk) {
      height: 152px;
   }

}

.c-site-head--reversed {
   background-color: transparent;
   position: absolute;
   z-index: 1;
   .c-logo-fill {
      fill: $white;
   }
   .c-site-nav__link {
      color: $white-80;
      &:before {
         background-color: $white;
      }
      &:hover {
         color: $white;
      }
      &.c-site-nav__link--active {
         color: $white;
      }
   }
   .c-mobile-nav-toggle__icon {
      background-color: $white;
      &:before,
      &:after {
         background-color: $white;
      }
   }
}
