///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.

$global-radius: 3px !default;
$global-radius--large: 6px !default;

$global-transition: 0.15s !default;
$global-transition--short: 0.075s !default;
$global-transition--long: 0.3s !default;
$global-transition--huge: 0.6s !default;
$global-transition--mega: 1.2s !default;

$global-easing-in: cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$global-easing-out: cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;

$base-font-family: 'Muli';
$heading-font-family: 'Playfair Display';
