.c-related {
   position: relative;
   z-index: 1;
   padding: $unit;
   @include mq($from: phablet) {
      padding: $unit * 1.5;
   }
   @include mq($from: tablet-port) {
      padding: $unit * 2;
   }
   @include mq($from: lap) {
      padding: $unit * 3;
   }
   @include mq($from: desk) {
      padding: $unit * 4;
   }
}

.c-related__context {
   font-family: $heading-font-family;
   position: relative;
   font-weight: 400;
   color: $paragraph;
   margin-bottom: $unit--large;
   @include font-size(14px,1.125);
   @include mq($from: phablet) {
      @include font-size(15px,1.25);
   }
   @include mq($from: tablet-port) {
      @include font-size(17px,1.25);
   }
   @include mq($from: lap) {
      @include font-size(20px,1.25);
   }
   @include mq($from: desk) {
      @include font-size(24px,1.25);
   }
   span {
      color: $paragraph-60;
   }
   &:before {
      position: absolute;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: $mantis;
      left: 0;
      bottom: -$unit;
      @include mq($from: tablet-land) {
         width: 7px;
         height: 7px;
      }
      @include mq($from: desk) {
         width: 8px;
         height: 8px;
      }
   }
}

.c-related__heading {
   margin-bottom: $unit--large;
   @include font-size(28px,1.125);
   @include mq($from: phablet) {
      @include font-size(32px,1.125);
   }
   @include mq($from: tablet-port) {
      @include font-size(36px,1.125);
   }
   @include mq($from: lap) {
      @include font-size(42px,1.125);
   }
   @include mq($from: desk) {
      @include font-size(48px,1.125);
   }
   span {
      color: $paragraph-60;
   }
}

.c-related__heading--long {
   margin-bottom: $unit--large;
   @include font-size(28px,1.125);
   @include mq($from: phablet) {
      @include font-size(30px,1.125);
   }
   @include mq($from: tablet-port) {
      @include font-size(32px,1.125);
   }
   @include mq($from: lap) {
      @include font-size(32px,1.125);
   }
   @include mq($from: desk) {
      @include font-size(34px,1.125);
   }
}

.c-related__lead {
   font-weight: 400;
   color: $paragraph;
   font-family: $heading-font-family;
   margin-bottom: $unit;
   @include font-size(18px,1.125);
   @include mq($from: phablet) {
      @include font-size(19px,1.25);
   }
   @include mq($from: tablet-port) {
      @include font-size(21px,1.25);
   }
   @include mq($from: lap) {
      @include font-size(22px,1.25);
   }
   @include mq($from: desk) {
      @include font-size(24px,1.25);
   }
}

.c-related__quote {
   font-weight: 700;
   font-style: italic;
   color: $firefly;
   font-family: $heading-font-family;
   margin-bottom: $unit--large;
   @include font-size(21px,1.25);
   @include mq($from: phablet) {
      @include font-size(22px,1.25);
   }
   @include mq($from: phablet-large) {
      @include font-size(24px,1.25);
   }
   @include mq($from: tablet-port) {
      @include font-size(28px,1.25);
   }
   @include mq($from: lap) {
      @include font-size(32px,1.25);
   }
   @include mq($from: desk) {
      @include font-size(36px,1.25);
   }
}

.c-related__pull {
   font-weight: 400;
   color: $firefly;
   font-family: $heading-font-family;
   margin-bottom: 0;
   @include font-size(25px,1.125);
   @include mq($from: phablet) {
      @include font-size(30px,1.125);
   }
   @include mq($from: tablet-port) {
      @include font-size(36px,1.125);
   }
   @include mq($from: lap) {
      @include font-size(42px,1.125);
   }
   @include mq($from: desk) {
      @include font-size(42px,1.125);
   }
}

.c-related--image {
    padding: 0 !important;
    background-color: $white;
}

.c-related--reversed {
   .c-related__context {
      color: $white;
      span {
         color: $white-60;
      }
   }
   .c-related__lead {
      color: $white-60;
   }
   .c-related__pull,
   .c-related__heading,
   .c-related__quote,
   .c-arrow-link {
      color: $white;
   }
}
