// Colour variables

// Green
$mantis:                rgba(133,190,66,1);
$mantis-90:             rgba(133,190,66,0.9);
$mantis-80:             rgba(133,190,66,0.8);
$mantis-70:             rgba(133,190,66,0.7);
$mantis-60:             rgba(133,190,66,0.6);

$mantis-40-solid:       rgba(206,229,183,1);
$mantis-60-solid:       rgba(182,216,148,1);
$mantis-80-solid:       rgba(157,203,112,1);


// Dark blue
$firefly:					rgba(0,45,64,1);
$firefly-90:            rgba(0,45,64,0.9);
$firefly-80:            rgba(0,45,64,0.8);
$firefly-70:            rgba(0,45,64,0.7);
$firefly-60:            rgba(0,45,64,0.6);
$firefly-10:            rgba(0,45,64,0.1);

// Teal
$persia:					   rgba(0,167,167,1);
$persia-60:             rgba(0,167,167,0.6);

// Purple
$seance:                rgba(113,42,118,1);
$seance-60:             rgba(113,42,118,0.6);

// Red
$amaranth:              rgba(231,52,76,1);
$amaranth-60:           rgba(231,52,76,0.6);

// Light blue
$seaspray:              rgba(116,201,227,1);
$seaspray-60:           rgba(116,201,227,0.6);

// Yellow
$sunglow:               rgba(255,206,50,1);
$sunglow-60:            rgba(255,206,50,0.6);

$paragraph:					rgba(103,109,102,0.9);
$paragraph-80:			   rgba(103,109,102,0.8);
$paragraph-60:			   rgba(103,109,102,0.6);

$heading:					rgba(0,45,64,1);
$heading-60:			   rgba(0,45,64,0.6);

$keyline:					rgba(232,233,234,1);
$keyline-60:			   rgba(232,233,234,0.6);

$smoke:                 rgba(246,246,246,1);

$electricity:           rgba(77,108,122,1);

$white:						rgba(255,255,255,1);
$white-5:					rgba(255,255,255,0.05);
$white-10:					rgba(255,255,255,0.1);
$white-15:					rgba(255,255,255,0.15);
$white-20:					rgba(255,255,255,0.2);
$white-25:					rgba(255,255,255,0.25);
$white-30:					rgba(255,255,255,0.3);
$white-35:					rgba(255,255,255,0.35);
$white-40:					rgba(255,255,255,0.4);
$white-45:					rgba(255,255,255,0.45);
$white-50:					rgba(255,255,255,0.5);
$white-55:					rgba(255,255,255,0.55);
$white-60:					rgba(255,255,255,0.6);
$white-65:					rgba(255,255,255,0.65);
$white-70:					rgba(255,255,255,0.7);
$white-75:					rgba(255,255,255,0.75);
$white-80:					rgba(255,255,255,0.8);
$white-85:					rgba(255,255,255,0.85);
$white-90:					rgba(255,255,255,0.9);
$white-95:					rgba(255,255,255,0.95);

$black:						rgba(0,0,0,1);
$black-5:					rgba(0,0,0,0.05);
$black-10:					rgba(0,0,0,0.1);
$black-15:					rgba(0,0,0,0.15);
$black-20:					rgba(0,0,0,0.2);
$black-25:					rgba(0,0,0,0.25);
$black-30:					rgba(0,0,0,0.3);
$black-35:					rgba(0,0,0,0.35);
$black-40:					rgba(0,0,0,0.4);
$black-45:					rgba(0,0,0,0.45);
$black-50:					rgba(0,0,0,0.5);
$black-55:					rgba(0,0,0,0.55);
$black-60:					rgba(0,0,0,0.6);
$black-65:					rgba(0,0,0,0.65);
$black-70:					rgba(0,0,0,0.7);
$black-75:					rgba(0,0,0,0.75);
$black-80:					rgba(0,0,0,0.8);
$black-85:					rgba(0,0,0,0.85);
$black-90:					rgba(0,0,0,0.9);
$black-95:					rgba(0,0,0,0.95);
