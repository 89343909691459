h1,h2,h3,h4,h5,h6 {
   font-family: $heading-font-family;
   color: $heading;
   font-weight: 700;
}

// h1 {
//    font-weight: 900;
// }


h1 {
   @include font-size($inuit-font-size-h1, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h1 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h1 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h1 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h1 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h1 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h1 * 1.75), 1.125, $important: false);
   }
}

h2 {
   @include font-size($inuit-font-size-h2, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h2 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h2 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h2 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h2 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h2 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h2 * 1.75), 1.125, $important: false);
   }
}

h3 {
   @include font-size($inuit-font-size-h3, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h3 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h3 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h3 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h3 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h3 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h3 * 1.75), 1.125, $important: false);
   }
}

h4 {
   @include font-size($inuit-font-size-h4, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h4 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h4 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h4 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h4 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h4 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h4 * 1.75), 1.125, $important: false);
   }
}

h5 {
   @include font-size($inuit-font-size-h5, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h5 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h5 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h5 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h5 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h5 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h5 * 1.75), 1.125, $important: false);
   }
}

h6 {
   @include font-size($inuit-font-size-h6, 1.125, $important: false);
   @include mq($from: phablet) {
     @include font-size(($inuit-font-size-h6 * 1.125), 1.125, $important: false);
   }
   @include mq($from: tablet-port) {
     @include font-size(($inuit-font-size-h6 * 1.25), 1.125, $important: false);
   }
   @include mq($from: tablet-land) {
     @include font-size(($inuit-font-size-h6 * 1.375), 1.125, $important: false);
   }
   @include mq($from: lap) {
     @include font-size(($inuit-font-size-h6 * 1.5), 1.125, $important: false);
   }
   @include mq($from: desk) {
     @include font-size(($inuit-font-size-h6 * 1.625), 1.125, $important: false);
   }
   @include mq($from: desk-wide) {
     @include font-size(($inuit-font-size-h6 * 1.75), 1.125, $important: false);
   }
}

// Additional top margin on sub h1 article headings

article {
   h2,h3,h4,h5,h6 {
      margin-top: $unit--large;
   }
}
