.c-carousel {
   margin-left: -$unit;
}

.c-carousel-item {
   transform: scale3d(0.9,0.9,1);
   opacity: 0.5;
   transition: transform $global-transition--huge ease-in-out, opacity $global-transition--huge ease-in-out;
   &.is-selected {
      transform: scale3d(1,1,1);
      opacity: 1;

   }
}

.c-carousel-btn {
   position: absolute;
   width: 36px;
   height: 36px;
   display: block;
   padding: 0;
   background-color: $persia;

   background-size: auto 50%;
   background-repeat: no-repeat;
   border: 0 none;
   top: 50%;
   transform: translateY(-50%);
   left: 0;
   @include box-shadow(0px,12px,48px,rgba(0,0,0,0.15));
   z-index: 200;
   cursor: pointer;
   @include mq($from: tablet-land) {
      width: 48px;
      height: 48px;
   }
}

.c-carousel-btn--next {
   left: auto;
   right: 0;
   background-image: url(../img/carousel-arrow-right.svg);
   background-position: calc(50% + 2px) center;
}

.c-carousel-btn--prev {
   background-image: url(../img/carousel-arrow-left.svg);
   background-position: calc(50% - 2px) center;
}

// Override to flickity page dots

.flickity-page-dots {
   bottom: -($unit * 2);
   @include mq($from: tablet-land) {
      bottom: -($unit * 3);
   }
   .dot {
      border-radius: 0;
      background-color: $white;
      transition: transform $global-transition ease-in-out;
      &.is-selected {
         background-color: $persia;
         transform: scale3d(1.25,1.25,1);
      }
   }
}
