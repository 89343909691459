
// Settings
@import "../node_modules/inuitcss/settings/settings.config";
$inuit-global-font-size:    14px !default;
$inuit-global-line-height:  24px !default;
@import "../node_modules/inuitcss/settings/settings.core";
@import "settings/settings.global";
@import "settings/settings.transitions";
@import "settings/settings.colour";

// Tools
@import "../node_modules/inuitcss/tools/tools.font-size";
@import "../node_modules/inuitcss/tools/tools.clearfix";
@import "../node_modules/inuitcss/tools/tools.hidden";
$mq-breakpoints: (
    mobile:         320px,
    mobile-large:   400px,
    phablet:        480px,
    phablet-large:  600px,
    tablet-port:    740px,
    tablet-land:    980px,
    lap:            1120px,
    desk:           1440px,
    desk-wide:		  1800px,
);
@import "../node_modules/sass-mq/mq";
@import "tools/tools.aliases";
@import "tools/tools.mixins";

// Generic
@import "../node_modules/inuitcss/generic/generic.box-sizing";
@import "../node_modules/inuitcss/generic/generic.normalize";
@import "../node_modules/inuitcss/generic/generic.reset";
@import "../node_modules/inuitcss/generic/generic.shared";

// Elements
@import "../node_modules/inuitcss/elements/elements.page";
@import "elements/elements.page";
$inuit-font-size-h1:  32px;
$inuit-font-size-h2:  24px;
$inuit-font-size-h3:  22px;
$inuit-font-size-h4:  20px;
$inuit-font-size-h5:  18px;
$inuit-font-size-h6:  16px;
$inuit-font-size-h7:  14px;
@import "../node_modules/inuitcss/elements/elements.headings";
@import "elements/elements.headings";
@import "../node_modules/inuitcss/elements/elements.images";
@import "../node_modules/inuitcss/elements/elements.tables";
@import "elements/elements.articles";
@import "elements/elements.forms";

// Objects
$inuit-wrapper-width: 1440px;
@import "../node_modules/inuitcss/objects/objects.wrapper";
@import "objects/objects.wrapper";
@import "../node_modules/inuitcss/objects/objects.layout";
@import "objects/objects.layout";
@import "../node_modules/inuitcss/objects/objects.media";
@import "../node_modules/inuitcss/objects/objects.flag";
@import "../node_modules/inuitcss/objects/objects.list-bare";
@import "../node_modules/inuitcss/objects/objects.list-inline";
@import "../node_modules/inuitcss/objects/objects.box";
@import "../node_modules/inuitcss/objects/objects.block";
@import "../node_modules/inuitcss/objects/objects.ratio";
@import "../node_modules/inuitcss/objects/objects.crop";
@import "../node_modules/inuitcss/objects/objects.table";
@import "../node_modules/inuitcss/objects/objects.pack";
@import "objects/objects.tabs";
@import "objects/objects.video";

// Components
@import "components/components.site-head";
@import "components/components.site-foot";
@import "components/components.logo";
@import "components/components.site-nav";
@import "components/components.mobile-nav-toggle";
@import "components/components.mobile-nav";
@import "components/components.hero";
@import "components/components.button";
@import "components/components.related";
@import "components/components.square-friend";
@import "components/components.arrow-link";
@import "components/components.inline-quote";
@import "components/components.hero-quote";
@import "components/components.partners";
@import "components/components.profile";
@import "components/components.contact-links";
@import "components/components.practitioner-profile";
@import "components/components.services-card";
@import "components/components.client-card";
@import "components/components.page-context";
@import "components/components.link-block";
@import "components/components.timeline";
@import "components/components.pipeline";
@import "components/components.carousel";
@import "components/components.community-post";
@import "components/components.section-heading";
@import "components/components.feature-list";

// Utilities
@import "utilities/utilities.motion";
@import "../node_modules/inuitcss/utilities/utilities.clearfix";
$inuit-offsets: true;
$inuit-fractions: 1 2 3 4 5 6 9 12;
@import "../node_modules/inuitcss/utilities/utilities.widths";
// @import "utilities/utilities.widths";
@import "../node_modules/inuitcss/utilities/utilities.headings";
@import "utilities/utilities.headings";
@import "../node_modules/inuitcss/utilities/utilities.spacings";
$inuit-responsive-spacing-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
  '-horizontal': '-left' '-right',
  '-vertical': '-top' '-bottom',
);
$inuit-responsive-spacing-properties: (
  'padding': 'padding',
  'margin': 'margin',
);
$inuit-responsive-spacing-sizes: (
  null: $inuit-global-spacing-unit,
  '-tiny': $inuit-global-spacing-unit-tiny,
  '-small': $inuit-global-spacing-unit-small,
  '-large': $inuit-global-spacing-unit-large,
  '-huge': $inuit-global-spacing-unit-huge,
  '-mega': ($inuit-global-spacing-unit * 5),
  '-none': 0
);
@import "../node_modules/inuitcss/utilities/utilities.responsive-spacings";
$inuit-responsive-negative-spacing-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
);
$inuit-responsive-negative-spacing-properties: (
  'margin': 'margin',
);
$inuit-responsive-negative-spacing-sizes: (
  null: -$inuit-global-spacing-unit,
  '-tiny': -$inuit-global-spacing-unit-tiny,
  '-small': -$inuit-global-spacing-unit-small,
  '-large': -$inuit-global-spacing-unit-large,
  '-huge': -$inuit-global-spacing-unit-huge,
  '-none': 0
);
@import "utilities/utilities.responsive-negative-spacings";
@import "../node_modules/inuitcss/utilities/utilities.print";
@import "../node_modules/inuitcss/utilities/utilities.hide";
@import "utilities/utilities.headings";
@import "utilities/utilities.text";
@import "utilities/utilities.layout";
@import "utilities/utilities.colour";
@import "utilities/utilities.shadows";
@import "utilities/utilities.mix-mode.scss";
@import "utilities/utilities.square-overlay.scss";
