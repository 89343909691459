.c-btn {
   display: inline-block;
   color: $white;
   padding: $unit--small $unit;
   @include font-size(12px,1.5);
   font-weight: 700;
   text-decoration: none;
   @include box-shadow(0px,12px,24px,rgba(0,0,0,0.15));
   transition: all $global-transition--long ease-in-out;
   position: relative;
   border: 0 none;
   cursor: pointer;
   &:hover {
      transition: all 1s ease-in-out;
   }
   @include mq($from: tablet-land) {
      @include font-size(14px,1.5);
   }
}

.c-btn--mantis {
   background-color: $mantis;
   &:hover {
      background-color: mix(black,$mantis,15%);
   }
}

.c-btn--firefly {
   background-color: $firefly;
   &:hover {
      background-color: mix(black,$firefly,15%);
   }
}

.c-btn--electricity {
   background-color: $electricity;
   &:hover {
      background-color: mix(black,$electricity,15%);
   }
}

.c-btn--square {
   margin: $unit--large 0;
   padding-right: 2 * $unit;
   &:before,
   &:after {
      content: "";
      position: absolute;
      display: block;
   }
   &:after {
      width: 8px;
      height: 8px;
      background-color: $white-30;
      right: $unit;
      top: 50%;
      transform: translateY(-50%);
   }
   &:before {
      width: 120px;
      height: 120px;
      background-image: url(../img/square-white.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: -1;
      top: 50%;
      right: -40px;
      transform: scale(1) translateY(-50%);
      transform-origin: top;
      transition: all $global-transition--long ease-in-out;
   }
   &:hover {
      &:before {
         transform: scale(1.1) translateY(-50%);
         transition: all 1.5s ease-in-out;
      }
   }
}

.c-btn--link-block,
.c-btn--contact {
   padding-left: 2 * $unit;
   margin-bottom: $unit--tiny;
   &:before,
   &:after {
      content: "";
      position: absolute;
      display: block;
   }
   &:before {
      width: 8px;
      height: 8px;
      background-color: $white-30;
      left: $unit;
      top: 50%;
      transform: translateY(-50%);
   }
}

.c-btn--contact {
   border-bottom: 0 none;
   box-shadow: none;
   color: $firefly;
   transition: all $global-transition--short ease-in-out;
   &:before {
      background-color: $mantis;
   }
   &:hover {
      color: $mantis;
      transition: all $global-transition ease-in-out;
   }
}
