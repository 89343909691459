// Box shadows

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	}
	@else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin text-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-text-shadow:inset $top $left $blur $color;
		-moz-text-shadow:inset $top $left $blur $color;
		text-shadow:inset $top $left $blur $color;
	}
	@else {
		-webkit-text-shadow: $top $left $blur $color;
		-moz-text-shadow: $top $left $blur $color;
		text-shadow: $top $left $blur $color;
	}
}

// Gradients

@mixin gradient-directional($start-color, $start-percent, $end-color, $end-percent, $deg) {
	background-color: $start-color;
	background-image: -webkit-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); // Opera 12
	background-image: linear-gradient($deg, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}