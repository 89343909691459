
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
    width: 100%;
    padding: $unit * 0.75;
    margin-bottom: $unit--small;
    appearance: none;
    border: 0 none;
    border-radius: 0;
    @include font-size(14px,1.25);
    color: $paragraph;
}

textarea {
    min-height: $unit * 8;
}

button[type="submit"] {
    &.c-btn {
        box-shadow: none;
    }
}

// Placeholder pseduo selectors

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $paragraph-60;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $paragraph-60;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $paragraph-60;
}
:-moz-placeholder { /* Firefox 18- */
  color: $paragraph-60;
}
