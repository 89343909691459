.o-video {
   display: block;
   width: 100%;
   height: 0;
   padding-bottom: 56.25%;
   position: relative;
   overflow: hidden;
   iframe,
   video {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
   }
}
