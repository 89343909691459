.o-tabs {
   position: absolute;
   left: 50%;
   transform: translate(-50%, -100%);
   top: 0;
   list-style: none;
   display: table;
   margin: 0;
   padding: 0;
   li {
      display: table-cell;
      padding: 0;

      &:nth-child(1) {
         .o-tab {
            background-color: $mantis-80;
         }
      }
      &:nth-child(2) {
         .o-tab {
            background-color: $mantis-70;
         }
      }
      &:nth-child(3) {
         .o-tab {
            background-color: $mantis-60;
         }
      }
      .o-tab {
         &.o-tab--active {
            background-color: $mantis;
         }
      }
   }
}

.o-tabs--firefly {
   li {
      &:nth-child(1) {
         .o-tab {
            background-color: $firefly-80;
         }
      }
      &:nth-child(2) {
         .o-tab {
            background-color: $firefly-70;
         }
      }
      &:nth-child(3) {
         .o-tab {
            background-color: $firefly-60;
         }
      }
      .o-tab {
         &.o-tab--active {
            background-color: $firefly;
         }
      }
   }
}

.o-tab {
   display: block;
   margin: 0;
   padding: $unit--small $unit;
   text-decoration: none;
   color: $white;
   text-align: center;
   @include font-size(12px, 1.125);
   font-weight: 700;
   @include mq($from: phablet-large) {
      @include font-size(14px, 1.125);
      padding: ($unit--small + $unit--tiny) ($unit + $unit--small);
   }
   @include mq($from: lap) {
      @include font-size(16px, 1.125);
      padding: ($unit--small + $unit--tiny) ($unit + $unit--small);
   }
}

.o-tab-content {
   margin-bottom: $unit--large;
   position: relative;
}

.o-tab-container {
   position: relative;
}
