.c-hero-quote {
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
   padding: $unit--huge 0;
   @include mq($from: phablet) {
      padding: $unit--huge  0;
   }
   @include mq($from: tablet-port) {
      padding: ($unit * 6)  0;
   }
   @include mq($from: tablet-land) {
      padding: ($unit * 8)  0;
   }
   @include mq($from: lap) {
      padding: ($unit * 12)  0;
   }
   @include mq($from: desk) {
      padding: ($unit * 12)  0;
   }
}

.c-hero-quote__content {
   font-weight: 600;
   @include font-size(15px, 1.75);
   @include mq($from: phablet) {
      @include font-size(16px, 1.75);
   }
   @include mq($from: tablet-port) {
      @include font-size(18px, 1.75)
   }
   @include mq($from: tablet-land) {
      @include font-size(20px, 1.75)
   }
   @include mq($from: lap) {
      @include font-size(22px, 1.75)
   }
   @include mq($from: desk) {
      @include font-size(24px, 1.75)
   }
   ul {
      li {
         list-style: none;
         position: relative;
         &:before {
            content: "";
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            background-color: $white-60;
            left: -($unit - $unit--tiny);
            top: 0.875em;
            transform: translateY(-50%);
         }

      }
   }
}

.c-hero-quote--about {
   background-image: url(../img/hero-home-about-no-square.jpg);

   @include mq($from: tablet-port) {
      background-image: url(../img/hero-home-about.jpg);

   }
}

.c-hero-quote--about-values {
   background-image: url(../img/hero-about-values-no-square.jpg);

   @include mq($from: tablet-port) {
      background-image: url(../img/hero-about-values.jpg);

   }
}
