.u-text-center {
   text-align: center;
}

.u-text-center\@tablet-port {
    @include mq($from: tablet-port) {
        text-align: center;
    }
}

.u-text-left {
   text-align: left;
}

.u-text-right {
   text-align: right;
}

.u-text-compact {
   line-height: 1.25 !important;
}

.u-text-reversed {
   color: $white;
}

.u-text-reversed-80 {
   color: $white-80;
}

.u-text-medium {
   font-weight: 400;
}


.u-text-key,
.u-text-key p {
   @include mq($from: lap) {
      @include font-size(19px,1.75);
   }
   @include mq($from: desk) {
      @include font-size(19px,1.75);
   }
   @include mq($from: desk-wide) {
      @include font-size(21px,1.75);
   }
}

.u-text-super-key,
.u-text-super-key p {
   @include font-size(16px,1.75);
   @include mq($from: phablet-large) {
      @include font-size(17px,1.75);
   }
   @include mq($from: tablet-port) {
      @include font-size(19px,1.75);
   }
   @include mq($from: tablet-land) {
      @include font-size(20px,1.75);
   }
   @include mq($from: lap) {
      @include font-size(21px,1.75);
   }
   @include mq($from: desk) {
      @include font-size(21px,1.75);
   }
   @include mq($from: desk-wide) {
      @include font-size(24px,1.75);
   }
}

.u-link-wrap {
   text-decoration: none;
   > * {
      text-decoration: none;
   }
}
