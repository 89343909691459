.c-timeline {
   padding: $unit 0;
   position: relative;
   &:before {
      top: 0;
      left: 0;
      display: block;
      position: absolute;
      content: "";
      width: 4px;
      height: 0;
      background-color: $white;
      transition: all $global-transition--huge $transition--ease-in-quart;
   }
   @include mq($from: tablet-land) {
      &:before {
         left: 50%;
         transform: translateX(-50%);
      }
   }
   &.u-motion-triggered {
      &:before {
         height: 100%;
      }
      .c-timeline-item {
         transform: translate(0);
         opacity: 1;
         transition: all $global-transition--huge $transition--ease-in-quart;
         @include mq($from: tablet-land) {
            &:nth-child(odd) {
               transform: translate(100%,0);
            }
            &:nth-child(even) {
               transform: translate(0,0);
            }
         }
      }
      .c-timeline-item {
         &:nth-child(1) {
            transition-delay: $global-transition--huge * 1;
         }
      }
      .c-timeline-item {
         &:nth-child(2) {
            transition-delay: $global-transition--huge * 1.75;
         }
      }
      .c-timeline-item {
         &:nth-child(3) {
            transition-delay: $global-transition--huge * 2.5;
         }
      }
      .c-timeline-item {
         &:nth-child(4) {
            transition-delay: $global-transition--huge * 3.25;
         }
      }

   }
}

.c-timeline-item {
   padding-left: $unit--large;
   margin-bottom: $unit--large;
   position: relative;
   opacity: 0;
   transform: translate(50%,0);
   &:before {
      display: block;
      content: "";
      position: absolute;
      background-color: $white;
      width: ($unit--large - $unit--small);
      height: 4px;
      top: $unit--tiny;
      left: 2px;
      @include mq($from: tablet-port) {
         top: 8px;
      }
      @include mq($from: tablet-land) {
         top: $unit--small;
      }
      @include mq($from: desk) {
         top: 16px;
      }
   }
   &:after {
      display: block;
      content: "";
      position: absolute;
      background-color: $mantis;
      width: 12px;
      height: 12px;
      opacity: 1;
      top: $unit--tiny;
      left: 0;
      transform: translate(-50%, -33.333%);
      @include mq($from: tablet-port) {
         top: 8px;
      }
      @include mq($from: tablet-land) {
         top: $unit--small;
      }
      @include mq($from: desk) {
         top: 16px;
      }
   }
   &:nth-child(even) {
      &:before {
         @include mq($from: tablet-land) {
            right: 2px;
            left: auto;
         }
      }
      &:after {
         @include mq($from: tablet-land) {
            right: 0;
            left: auto;
            transform: translate(50%, -33.333%);
         }

      }
   }
   &:nth-child(1) {
      &:after {
         background-color: $mantis-40-solid;
      }
   }
   &:nth-child(2) {
      &:after {
         background-color: $mantis-60-solid;
      }
   }
   &:nth-child(3) {
      &:after {
         background-color: $mantis-80-solid;
      }
   }
   &:nth-child(4) {
      &:after {
         background-color: $mantis;
      }
   }
   &:last-of-type {
      margin-bottom: 0;
   }
   @include mq($from: tablet-land) {
      width: 50%;
      &:nth-child(odd) {
         transform: translate(150%,0);
      }
      &:nth-child(even) {
         text-align: right;
         padding-right: $unit--large;
         padding-left: 0;
         transform: translate(-50%,0);
      }
   }
}

.c-timeline-item__description {
   margin-bottom: 0;
   @include font-size(14px, 1.5);
   @include mq($from: tablet-port) {
      @include font-size(15px, 1.5);
   }
   @include mq($from: lap) {
      @include font-size(16px, 1.5);
   }
}
