
.u-motion {
   transition: all $global-transition--huge $transition--ease-in-expo $global-transition--long;
}

.u-motion-triggered {
   @include mq($from: tablet-port) {
      &.u-motion-up {
         transform: translate3d(0,0,0);
      }
   }
   @include mq($from: tablet-port) {
      &.u-motion-zoom-out {
         transform: scale(1);
      }
   }
   @include mq($from: tablet-port) {
      &.u-motion-fade-in {
         opacity: 1;
      }
   }
}

.u-motion-up {
   transform: translate3d(0,$unit--large,0);
}

.u-motion-zoom-out {
   transform: scale(0.875);
}

.u-motion-fade-in {
   opacity: 0;
}
