.u-square-overlay {
   position: relative;
   display: block;
   &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url(../img/square-white.svg);
      background-repeat: no-repeat;
      background-size: cover;
   }
}
