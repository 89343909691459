.c-community-post {
   padding: $unit;
   background-color: $white;
   margin-bottom: $unit;

   @include mq($from: phablet) {
      padding: $unit--large;
   }
   @include mq($from: tablet-land) {
      padding: ($unit * 3);
   }
   @include mq($from: desk) {
      padding: ($unit * 3.5);
   }
}

.c-community-post--reversed {
   .c-community-post__date,
   .c-community-post__heading,
   .c-community-post__intro,
   .c-arrow-link {
      color: $white;
   }
}

.c-community-post__img {
   width: 100%;
}

.c-community-post__date {
   @include font-size(14px);
   margin-bottom: $unit--small;
}

.c-community-post__heading {
   @include mq($from: tablet-land) {
      margin-bottom: $unit--large;
   }
}

.c-community-post__intro {
   @include font-size(16px,1.75);
   @include font-size(14px, 1.75);
   @include mq($from: tablet-port) {
      @include font-size(15px, 1.75);
   }
   @include mq($from: lap) {
      @include font-size(16px, 1.75);
   }
   @include mq($from: tablet-land) {
      margin-bottom: $unit--large;
   }
}
