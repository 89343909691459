.c-client-card {
   background-color: $white;
   @include box-shadow(0px,12px,24px,rgba(0,0,0,0.05));
   margin-bottom: $unit;
}

.c-client-card__img-wrap {
   height: 0;
   padding-bottom: 52.586%;

}

.c-client-card__img {
   width: 100%;
}

.c-client-card__content {
   padding: $unit;
   @include mq($from: tablet-port) {
      padding: ($unit * 1.5);
   }
   @include mq($from: lap) {
      padding: ($unit * 2);
   }
}

.c-client-card__heading {
   min-height: 3em;
   max-height: 3em;
}

.c-client-card__description {
   min-height: 6em;
   max-height: 6em;
   @include font-size(14px,1.5);
   @include mq($from: tablet-port) {
      @include font-size(15px,1.5);
   }
   @include mq($from: lap) {
      @include font-size(16px,1.5);
   }
}

.c-client-card--partner {
    .c-client-card__heading {
       min-height: 0;
       max-height: none;
    }
    .c-client-card__description {
       min-height: 6em;
       max-height: 6em;
       overflow: hidden;
       margin-bottom: $unit--large;
   }
}
