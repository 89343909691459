// html {
//    max-width: 1920px;
//    margin: 0 auto;
//    position: relative;
//    background-color: $smoke;
// }

body {
   font-family: $base-font-family, sans-serif;
   font-weight: 300;
   color: $paragraph;
   background-color: $smoke;
   overflow-x: hidden;
   -webkit-font-smoothing: antialiased;
}

strong {
   font-weight: 700;
   color: $firefly;
}

a {
    color: $mantis;
    &:hover {
        text-decoration: none;
    }
}

// Scaling for block level elements

p,
ol, ul,
pre,
address,
blockquote,
dl,
fieldset,
form,
table {
   @include font-size(14px,1.75);
   @include mq($from: phablet) {
      @include font-size(15px,1.75);
   }
   @include mq($from: tablet-port) {
      @include font-size(16px,1.75);
   }
   @include mq($from: tablet-land) {
      @include font-size(16px,1.75);
   }
   @include mq($from: lap) {
      @include font-size(17px,1.75);
   }
   @include mq($from: desk) {
      @include font-size(18px,1.75);
   }
   @include mq($from: desk-wide) {
      @include font-size(18px,1.75);
   }
}
