article {
   img {
      margin-top: $unit;
      margin-bottom: $unit--large;
      width: 100%;
   }
   > p:first-of-type {
      color: $firefly;
      font-weight: 700;
      a {
         font-weight: 300;
         color: $paragraph;
      }
   }
   a {
      text-decoration: none;
      border-bottom: solid 1px $mantis;
      transition: all $global-transition ease-in-out;
      color: $paragraph;
      background-color: $white-75;
      &:hover {
         color: $firefly;
         border-bottom-color: $firefly;
         // background-color: transparent;
      }
   }
   ul {
      li {
         list-style: none;
         position: relative;
         &:before {
            content: "";
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            background-color: $mantis;
            left: -($unit - $unit--tiny);
            top: 0.875em;
            transform: translateY(-50%);
         }

      }
   }
   blockquote {
      background-color: $white;
      // margin-left: -$unit--large;
      // margin-right: -$unit--large;
      margin-top: $unit--large;
      margin-bottom: $unit--large;
      position: relative;
      padding: $unit--large;
      margin-left: -$unit;
      margin-right: -$unit;
      @include mq($from: lap) {
          padding: $unit * 3;
          margin-top: $unit--huge;
          margin-bottom: $unit--huge;
          margin-left: -$unit--large;
          margin-right: -$unit--large;

      }
      &:before,
      &:after {
         display: block;
         position: absolute;
         content: "";
      }
      &:before {
         width: 4px;
         height: 100%;
         background-color: $mantis;
         left: 0;
         top: 0;
      }
      &:after {
         // width: 12px;
         // height: 12px;
         // background-color: $mantis;
         // left: 0;
         // top: 50%;
         // transform: translate(-50%,-50%);
      }
      cite {
         font-size: 0.75em;
         color: $firefly;
         font-weight: 700;
         font-style: normal;
         line-height: 1.25 !important;
      }
   }
   blockquote,
   blockquote p,
   blockquote ul {
      @include font-size(14px, 1.75);
      color: $paragraph;
      font-style: italic;
      @include mq($from: phablet) {
         @include font-size(15px,1.75);
      }
      @include mq($from: tablet-port) {
         @include font-size(16px,1.75);
      }
      @include mq($from: tablet-land) {
         @include font-size(16px,1.75);
      }
      @include mq($from: lap) {
         @include font-size(17px,1.75);
      }
      @include mq($from: desk) {
         @include font-size(18px,1.75);
      }
      @include mq($from: desk-wide) {
         @include font-size(18px,1.75);
      }

   }

}
