.c-link-block {
   background-color: $white;
   padding: $unit $unit $unit--large $unit;
   margin-bottom: $unit--large;
   @include mq($from: tablet-land) {
      padding: ($unit * 1.5) ($unit * 1.5) ($unit * 2) ($unit * 1.5);
   }
   .c-btn--link-block {
      margin-left: -($unit * 1.5);
      @include mq($from: phablet) {
         margin-left: -($unit * 2);
      }
   }
}

.c-link-block__heading {
   font-weight: 400;
   color: $paragraph;
   margin-bottom: $unit;
   @include mq($from: tablet-land) {
      margin-bottom: ($unit * 1.5);
   }
}
