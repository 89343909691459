// Site nav toggle

.c-mobile-nav-toggle {
	display: block;
	position: absolute;
	z-index: 50;
	width: 32px;
	height: 20px;
	right: $unit;
	top: 50%;
	transform: translateY(-50%);
	&.c-mobile-nav--visible {
		.c-mobile-nav-toggle__icon {
			background-color: $white;
			transition: all $global-transition--long $transition--ease-in-quart;
			&:before {
				transition: all $global-transition--long $transition--ease-in-quart;
				left: 8px;
				right: 0;
				background-color: $white;
			}
			&:after {
				transition: all $global-transition--long $transition--ease-in-quart $global-transition;
				left: 14px;
				right: 0;
				background-color: $white;
			}
		}
	}
	@include mq($from: tablet-port) {
		display: none;
	}
}

// Animated toggle icon

.c-mobile-nav-toggle__icon {
	display: block;
	position: absolute;
	background-color: $mantis;
	width: 32px;
	height: 2px;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	transition: all $global-transition--long $transition--ease-in-quart;
	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		background-color: $mantis;
		height: 2px;
		left: 0;

	}
	&:before {
		width: 24px;
		top: -7px;
		transition: all $global-transition--long $transition--ease-in-quart $global-transition;
	}
	&:after {
		width: 18px;
		top: 7px;
		transition: all $global-transition--long $transition--ease-in-quart;
	}
}

// Toggle text

.c-mobile-nav-toggle__text {
	display: none;
}
