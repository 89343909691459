// Standard easing as cubic-beziers

$transition--ease-in-sine:       cubic-bezier(0.47, 0, 0.745, 0.715);
$transition--ease-out-sine:      cubic-bezier(0.39, 0.575, 0.565, 1);
$transition--ease-in-out-sine:   cubic-bezier(0.445, 0.05, 0.55, 0.95);

$transition--ease-in-quad:       cubic-bezier(0.55, 0.085, 0.68, 0.53);
$transition--ease-out-quad:      cubic-bezier(0.25, 0.46, 0.45, 0.94);
$transition--ease-in-out-quad:   cubic-bezier(0.455, 0.03, 0.515, 0.955);

$transition--ease-in-cubic:      cubic-bezier(0.55, 0.055, 0.675, 0.19);
$transition--ease-out-cubic:     cubic-bezier(0.215, 0.61, 0.355, 1);
$transition--ease-in-out-cubic:  cubic-bezier(0.645, 0.045, 0.355, 1);

$transition--ease-in-quart:      cubic-bezier(0.895, 0.03, 0.685, 0.22);
$transition--ease-out-quart:     cubic-bezier(0.165, 0.84, 0.44, 1);
$transition--ease-in-out-quart:  cubic-bezier(0.77, 0, 0.175, 1);

$transition--ease-in-quint:      cubic-bezier(0.755, 0.05, 0.855, 0.06);
$transition--ease-out-quint:     cubic-bezier(0.23, 1, 0.32, 1);
$transition--ease-in-out-quint:  cubic-bezier(0.86, 0, 0.07, 1);

$transition--ease-in-expo:       cubic-bezier(0.95, 0.05, 0.795, 0.035);
$transition--ease-out-expo:      cubic-bezier(0.19, 1, 0.22, 1);
$transition--ease-in-out-expo:   cubic-bezier(1, 0, 0, 1);

$transition--ease-in-circ:       cubic-bezier(0.6, 0.04, 0.98, 0.335);
$transition--ease-out-circ:      cubic-bezier(0.075, 0.82, 0.165, 1);
$transition--ease-in-out-circ:   cubic-bezier(0.785, 0.135, 0.15, 0.86);

$transition--ease-in-back:       cubic-bezier(0.6, -0.28, 0.735, 0.045);
$transition--ease-out-back:      cubic-bezier(0.175, 0.885, 0.32, 1.275);
$transition--ease-in-out-back:   cubic-bezier(0.68, -0.55, 0.265, 1.55);
