.c-practitioner-profile {
   background-color: $white;
   padding: $unit;
   margin-bottom: $unit;
   @include mq($from: tablet-port) {
      padding: ($unit * 1.5) ($unit * 1.5) ($unit * 1.5) ($unit * 1.5);
      margin-bottom: $unit--large;
   }
   @include mq($from: desk) {
      padding: ($unit * 2) ($unit * 2) ($unit * 2) ($unit * 2);

   }
}

.c-practitioner-profile__img {
   position: relative;
   &:before {
      position: absolute;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: $mantis;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      @include mq($from: tablet-land) {
         width: 7px;
         height: 7px;
      }
      @include mq($from: desk) {
         width: 8px;
         height: 8px;
      }
   }
}

.c-practitioner-profile__heading {
   margin-bottom: 0;
   color: $paragraph;
   font-weight: 400;
}

.c-practitioner-profile__content {
   margin-top: $unit;
   @include font-size(14px, 1.625);
   @include mq($from: tablet-port) {
      @include font-size(15px, 1.625);
   }
   @include mq($from: tablet-land) {
      margin-top: ($unit * 1.5);
   }
   @include mq($from: lap) {
      @include font-size(16px, 1.625);
   }

}
