
.o-wrapper {
   @include mq($from: tablet-port) {
      padding-left: 0;
      padding-right: 0;
   }
}

.o-wrapper--narrow {
   max-width: 1024px;
}

.o-wrapper--medium {
   max-width: 1280px;
}

.o-wrapper--soft {
   @include mq($from: tablet-port) {
      padding-left: $unit;
      padding-right: $unit;
   }
}

.o-wrapper--carousel {
   padding-left: 0 !important;
   padding-right: 0 !important;
}
